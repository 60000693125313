import React from "react"
import Slider from "react-slick"
import { v4 as uuidv4 } from "uuid"
import { Button } from "semantic-ui-react"
import { Link } from "gatsby"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import "./index.scss"

let heroHeight = "600px"

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  autoplay: false,
  autoplaySpeed: 5000,
  slidesToScroll: 1,
  draggable: false,
  arrows: false,
  focusOnSelect: false,
}

export default function HeroSlider({ slides }) {
  return (
    <div className="hero-slider">
      <div className="slider">
        <Slider {...settings}>
          {slides.map(slide => (
            <div
              className="slide"
              key={uuidv4()}
              style={{
                height: "100%",
                minHeight: heroHeight,
                height: heroHeight,
                width: "100%",
              }}
            >
              <div
                className="slide-wrapper"
                style={{
                  backgroundImage: `url(${slide.image})`,
                  backgroundPosition: "center center",
                  backgroundRepeat: `no-repeat`,
                  backgroundSize: "cover",
                  height: "100%",
                  minHeight: heroHeight,
                  height: heroHeight,
                  width: "100%",
                }}
              >
                <div className="slide-content">
                  <h3>{slide.title}</h3>
                  {slide.text}
                  {slide.extra && (
                    <div className="extra">
                      <Link to={slide.link}>
                        <Button type="button" size="tiny">
                          {slide.extra}
                        </Button>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}

HeroSlider.defaultProps = {
  slides: [],
}
