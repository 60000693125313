import React from "react"
// import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import { PageLayout } from "../components/layouts"
// import Image from "../components/image"
import SEO from "../components/seo"

import TextBlock from "../components/textBlock"
import TextImageBlock from "../components/textImageBlock"
import SingleColumnBlock from "../components/singleColumnBlock"
import PageWrapper from "../components/pageWrapper"

import HeroSlider from "../components/heroSlider"
import ParallaxSection from "../components/parallaxSection"

import Blockquote from "../components/blockquote"

import ActiveItemGallery from "@components/ActiveItemGallery"

import {
  ContactForm,
  FormWrapper,
  RequestMoreInfo,
  RequestQuote,
} from "@components/Forms"

import {
  RequestMoreInfoButton,
  ContactUsTodayButton,
  DownloadProductBrochureButton,
} from "../components/buttons"

import { QuotePanel } from "../components/quotes"

const AntiqueMirror = () => {
  const data = useStaticQuery(graphql`
    query {
      parallax: file(relativePath: { eq: "AdobeStock_250630318.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <PageLayout
      heroTitle="ANTIQUE MIRROR"
      heroBackgroundImage="/images/antique-mirror/header-antique-mirror-bianco-e-nero.jpg"
    >
      <SEO title="Antique Mirror | ArtVue Glass" ogDescription="See our wide selection of antique mirrors. Find the perfect look for your home or office with a high quality and durable antique mirror from ArtVue Glass!" />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>Create a Vintage, Old-world Look With a Sense of Elegance</h2>
            <p>
              Whether you are looking to enhancing a large architectural
              building or create a quaint living space, the antique mirrors
              offered by ArtVue Glass will give you many different design
              options to help achieve your desired look and feel.
            </p>
            <p>
              Customers, designers, decorators, and architects can choose from
              our wide selection of antique mirrors. Because of our continued
              dedication and strong supplier relationships, we maintain a large
              stock of both the most popular styles and unique designs for your
              choosing.
            </p>
                        <FormWrapper
              modal
              buttonProps={{
                content: "Request More Info",
                size: "tiny",
                icon: "calendar",
              }}
            >
              <RequestMoreInfo />
            </FormWrapper>
          </div>
        }
      /> 
      <ActiveItemGallery
        items={galleryItems}
        galleryTitle="Some of our most popular antique mirror glass include:"
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h4>Notes:</h4>
            <p>
              Our antique mirrors contain a superior backing paint that protects the antique mirror pattern, allows for a strong adhesive bonding surface, and lets us finish the edges without flaking.
            </p>
            <p>
              Actual antique mirror pattern and color may vary slightly from the image shown.
            </p>
          </div>
        }
      /> 
      <div className="section textured">
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h3>Features & Benefits</h3>
              <ul>
                <li>
                  Each piece of glass is professionally finished through a proprietary oxidation and silvering process that cannot be duplicated by DIY or other aging methods.
                </li>
                <li>
                  Authentic antiqued variations in color and appearance.
                </li>
                <li>
                  Standard smooth mirror back for easy installation.
                </li>
                <li>
                  Actual sheets may vary in color and appearance from samples and from sheet to sheet.
                </li>
                <li>
                  We work with all customers to maximize sheet utilization and reduce scrap.
                </li>
                <li>
                  Custom cut to any size.
                </li>
              </ul>
            </div>
          }
          textRight={
            <div>
              <h3>Mirror Tiles Also Available</h3>
              <p>
                <ul>
                  <li>
                    All antique mirror patterns are also available as pre-cut tiles.
                  </li>
                  <li>
                    Standard sizes of either 4” x 6” or 4” x 12”.
                  </li>
                  <li>
                    Order all of the same pattern or mix and match for a unique design.
                  </li>
                  <li>
                    All tiles come with a light seamed edge for safe handling.
                  </li>
                </ul>
              </p>
            </div>
          }
        />
        <SingleColumnBlock
          textPadded
          text={
            <div>
              <p>
                Need safety specifications to further prevent breakage? Laminated antique mirror available. Have special design requirements, such as combining with other glass products for a truly unique piece?
              </p>
                          <FormWrapper
              modal
              buttonProps={{
                content: "Request More Info",
                size: "tiny",
                icon: "calendar",
              }}
            >
              <RequestMoreInfo />
            </FormWrapper>
            </div>
          }
        /> 
      </div>
      <PageWrapper color="grey">
        <SingleColumnBlock
          textPadded
          text={
            <div>
              <h2>20 Best Examples of Antique Mirror Applications</h2>
              <p>
                When it comes to mirror glass, sometimes you want the glitz and glamour of a standard piece. But when looking for something that will stand out in style as well as function, there's nothing quite like antique or distressed mirror glass. Here are twenty different applications of antique mirrors worth considering when designing your next space.
              </p>
            </div>
          }
        /> 
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h3>Residential Interiors</h3>
              <ol>
                <li>Bathrooms</li>
                <li>Hallways</li>
                <li>Living Room</li>
                <li>Framed</li>
                <li>Tiled Backsplash</li>
                <li>Furniture</li>
                <li>Over a Fireplace</li>
                <li>In the Bedroom</li>
                <li>Behind a Wet Bar</li>
              </ol>
            </div>
          }
          textRight={
            <div>
            <div>
              <h3>Commercial Interiors</h3>
              <ol start="10">
                <li>Hospitality</li>
                <li>Lobby Areas</li>
                <li>Restaurants and Bars</li>
                <li>Hotels</li>
                <li>Elevator Cab Interiors</li>
                <li>Hallways</li>
                <li>Lounges</li>
                <li>Casinos</li>
                <li>Department Stores</li>
                <li>Boutique Shops</li>
                <li>Wall Cladding</li>
              </ol>
            </div>
            </div>
          }
        />
        <SingleColumnBlock
          textPadded
          text={
            <div>
              <h2>Residential Interiors</h2>
              <p>
                Antique mirrors are best suited in many ways for residential spaces. Particularly if the building is a period structure or if you are just looking to achieve a certain style. Whether used in a reception area/hallway or living room or bathroom, they can elevate the whole feeling of a room.
              </p>
            </div>
          }
        /> 
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h3>1. Bathroom Antique Mirrors</h3>
              <p>
                Most effective bathroom design ideas use a combination of modern and traditional. An antique mirror is a great addition to this kind of bathroom where there is a mix of extravagance with the minimal. An important decision to make when choosing an antique mirror for your bathroom is choosing the right glass that will help make the style of these beautiful mirrors stand out.
              </p>
            </div>
          }
          textRight={
            <div>
              <img
                src="/images/antique-mirror/antique-mirror-bath-vanity.jpg"
                alt="Bathroom Antique Mirrors"
              />
            </div>
          }
        />
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h3>2. Vintage Mirrors</h3>
              <p>
                Vintage mirrors offer an incredible amount of versatility and flexibility. For example, vintage mirrors when paired with amazing wooden paneling can create the perfect accent for a hallway. The result can be both exquisitely sophisticated and exceptionally functional.
              </p>
            </div>
          }
          textRight={
            <div>
              <img
                src="/images/antique-mirror/vintage-antique-mirror.jpg"
                alt="Vintage Mirrors"
              />
            </div>
          }
        />
        <SingleColumnBlock
          textPadded
          text={
            <div>
              <h2>3. Antique Mirrors in a Living Room</h2>
              <p>
                Living rooms where much of the entertaining of guests and, well, living takes place in a home are ideal areas to use antique mirrors. The stunning array of vintage mirror frames we have available can give the room a focal point. Even the most simplistic and plain living space can have a greater sense of grandeur with an antique mirror in place.
              </p>
            </div>
          }
        /> 
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h3>4. Framed Antique Mirror</h3>
              <p>
                While frameless vintage and antique mirrors do have their place, there is something truly special about a framed antique mirror. Particularly when the frame has an intricate pattern or etching around it or engaging adornments that make for great talking points.
              </p>
            </div>
          }
          textRight={
            <div>
              <img
                src="/images/antique-mirror/framed-antique-mirror.jpg"
                alt="Framed Antique Mirror"
              />
            </div>
          }
        />
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h3>5. Antique Mirror Tiled Backsplash</h3>
              <p>
                Whether you are looking to give a small room a greater sense of depth and space or want to create an interesting and almost thought-provoking back to a larger room or even a kitchen, antique mirror tile backsplashes and antique mirror glass walls are great for this purpose. As well as enhancing the aesthetics they can provide a very functional purpose too.
              </p>
            </div>
          }
          textRight={
            <div>
              <img
                src="/images/antique-mirror/header-antique-mirror-bianco-e-nero2.jpg"
                alt="Antique Mirror Tiled Backsplash"
              />
            </div>
          }
        />
        <SingleColumnBlock
          textPadded
          text={
            <div>
              <h2>6. Furniture</h2>
              <p>
                Furniture stores, especially those selling high-end and very unique traditional pieces need to be able to show how their products would fit into customer’s homes and properties. In mock-ups of home environments, it can be useful to have antique mirrors, particularly when showcasing vintage furniture pieces like wingback armchairs, sideboards, and dressers.
              </p>
            </div>
          }
        />
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h3>7. Antique Mirror over a Fireplace</h3>
              <p>
                This mirror over the fireplace adds a sense of light and depth to this space without taking away from other decor. It can add an original look that is sure to draw eyes in itself, creating fascinating artwork for all those who gaze upon it.
              </p>
            </div>
          }
          textRight={
            <div>
              <img
                src="/images/antique-mirror/antique-mirror-over-fireplace-living-room.jpg"
                alt="Antique Mirror over a Fireplace"
              />
            </div>
          }
        />
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h3>8. Antique Mirror in the Bedroom</h3>
              <p>
                The best places to place your antique mirror are across from your bed, in front of it or on a side wall. Placing the mirror opposite the footboard can balance out an asymmetrical room and highlight its beauty; placing it between two doors will make for symmetry while showcasing its charm. You could also choose a less symmetric spot if you’d like to have some fun with creative decorating!
              </p>
            </div>
          }
          textRight={
            <div>
              <img
                src="/images/antique-mirror/antique-mirror-bed-room.jpg"
                alt="Antique Mirror in the Bedroom"
              />
            </div>
          }
        />
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h3>9. Antique Mirror Behind wet Bars</h3>
              <p>
                In a wet bar in a home setting, for an added touch of both realism and traditional decoration, antique mirrors can help to make the bar area shine. The reflection of the colorful and exquisitely shaped bottles can become a focal point.
              </p>
            </div>
          }
          textRight={
            <div>
              <img
                src="/images/antique-mirror/antique-mirror-wet-bar.jpeg"
                alt="Antique Mirror Behind wet Bars"
              />
            </div>
          }
        />
        <SingleColumnBlock
          textPadded
          text={
            <div>
              <h2>Commercial Interiors</h2>
              <p>
                While many may think to only feature antique and vintage mirrors in residential properties, they can have pride of place in commercial property too. If you want to project an air of sophistication as a luxury brand, for instance, a vintage mirror in a prominent position can help hold together a carefully chosen décor that includes other vintage and antique furnishings and accessories too.
              </p>
              <h3>10. Hospitality</h3>
              <p>
                Within the hospitality industry, there are many great uses for antique and vintage mirrors. If you have designed a period-specific or traditional dining experience, a vintage mirror can help to sell the right atmosphere and ambiance.
              </p>
            </div>
          }
        /> 
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h3>11. Lobby Areas</h3>
              <p>
                Lobby areas can be very plain and if you want to help expand the elegance in the rest of the property to the lobby without overdoing it, the use of antique mirrors can do the trick. Whether you opt for a large-scale vintage mirror wall or series of smaller individual pieces, a touch of glitz, glamor, and decorative drama can enhance any space.
              </p>
            </div>
          }
          textRight={
            <div>
              <img
                src="/images/antique-mirror/antique-mirror-hotel-lobby.jpg"
                alt="Lobby Areas"
              />
            </div>
          }
        />
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h3>12. Restaurants and Bars</h3>
              <p>
                Restaurants and bars that have a certain old-world theme or décor and color scheme can be enhanced with the use of stylish and traditional vintage mirrors. In the bar area or even in the dining section, it can help to add a touch of class while giving the illusion of a much larger space.
              </p>
            </div>
          }
          textRight={
            <div>
              <img
                src="/images/antique-mirror/antique-mirror-bar-restaurant.jpg"
                alt="Restaurants and Bars"
              />
            </div>
          }
        />
        <SingleColumnBlock
          textPadded
          text={
            <div>
              <h3>13. Hotels</h3>
              <p>
                Although noted above, it’s not just in elevator cabs that vintage and antique mirrors can be used to enhance the sophistication and elegance of hotels. Whether it’s in the hallways or the rooms themselves, these decorative and practical pieces can add to the charm of a hotel. Antique bathroom mirrors, vanities, and dressing room-size mirrors can all take up pride of place in a hotel room to help give it a real elegance while offering functionality too.
              </p>
            </div>
          }
        /> 
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h3>14. Elevator Cab Interiors</h3>
              <p>
                You may not think that elevator cabs would be the best location for installing antique mirrors. However, in boutique hotels, condos, apartment blocks, and design-conscious shopping malls, and other retail establishments, vintage mirrors can extend the experience from the front entrance throughout the building.
              </p>
            </div>
          }
          textRight={
            <div>
              <img
                src="/images/antique-mirror/antique-mirror-elevator.jpg"
                alt="Elevator Cab Interiors"
              />
            </div>
          }
        />
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h3>15. Hallways</h3>
              <p>
                A hall can look longer, wider, or brighter with the placement of antique mirrors within it. For a larger space that appears to stretch out further in length: place large reflective surfaces at each end wall. If you want a narrow corridor to appear broader and more open on both sides; then install bigger sized fixtures along either side walls instead.
              </p>
            </div>
          }
          textRight={
            <div>
              <img
                src="/images/antique-mirror/antique-mirror-commercial-interior-hallway.jpg"
                alt="Hallways"
              />
            </div>
          }
        />
        <SingleColumnBlock
          textPadded
          text={
            <div>
              <h3>16. Lounges</h3>
              <p>
                Lounges being the perfect place for informal meetings regarding business or friends socializing makes them another ideal setting where antique mirrors can be put to good use. They can help add an air of sophistication, whether the rest of the décor is more traditional or contemporary.
              </p>
              <h3>17. Casinos</h3>
              <p>
                Casinos are often about exuberance and classiness, with a mixture of the old with the new. This meeting of worlds in décor and furnishings is the perfect environment for well-placed, intricately designed vintage mirrors. Not only do they help with setting the high-class tone, but they also can provide a practical use for security keeping watch on patrons.
              </p>
              <h3>18. Department Stores</h3>
              <p>
                Upmarket department stores that house legacy brands and boutique concessions can benefit from the use of antique mirrors. Both as decorative pieces and in changing rooms and bathrooms to help carry the theme of the store and the brands it accommodates.
              </p>
              <h3>19. Boutique Shops</h3>
              <p>
                Antique mirrors are perfect for making a small boutique store feel bigger. They bring in additional light and can even make you forget about the space's size when they reflect your surroundings so well. Our favorite part of using antique mirrors is how much character they're able to add, which makes it more enjoyable to shop!
              </p>
            </div>
          }
        /> 
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h3>20. Wall Cladding & Wall Tiles</h3>
              <p>
                In place of more traditional wall claddings and wall tiles, antique mirrors can be used to add extra depth, while enhancing the elegance and classic look and feel of any building or room.
              </p>
            </div>
          }
          textRight={
            <div>
              <img
                src="/images/antique-mirror/antique-mirror-wall-cladding.jpg"
                alt="Wall Cladding & Wall Tiles"
              />
            </div>
          }
        />
        <SingleColumnBlock
          textPadded
          text={
            <div>
              <h2>Any Size, any Space</h2>
              <p>
                We can fulfill all orders for mirrors. Whether you have standard dimensions you are working with and looking to fill or are working with something more customized and custom, we can help. We are fully skilled and experienced in producing oversized vanity and bathroom mirrors, large and expansive floor-to-ceiling mirrors for gyms and fitness rooms, full-length mirrors for fitting rooms and dressing rooms, closet door bifold or sliding mirrors.
              </p>
              <p>
                If you need help finding the right kind of antique mirror for your property, one of our team will be happy to discuss this with you or your designer/decorator. Just get in touch and we can get the ball rolling.
              </p>
              <h4>Contact Us Today for A Free Consultation!</h4>
                          <FormWrapper
              modal
              buttonProps={{
                content: "Contact Us Today",
                size: "tiny",
                icon: "phone",
              }}
            >
              <ContactForm />
            </FormWrapper>
            </div>
          }
        />
      </PageWrapper>
    </PageLayout>
  )
}

export default AntiqueMirror

const galleryItems = [
  {
    key: 1,
    title: "ESPRESSO ANTIQUE MIRROR",
    images: ["/images/antique-mirror/antique-mirror-espresso.jpg"],
    description:
      "​Espresso Antique Mirror pattern has a rich, brown, mottled color that replicates the appearance and feel of original 16th, 17th, and 18th-century mirrors. One of our newest additions and quickly becoming a popular favorite.",
    thickness: "1/4",
    factory_size: "72 X 96",
    stock_size: "36 X 96 or 48 X 72",
    cut_to_size: "Yes",
    edgework: "Seamed, Flat Polished or Beveled",
  },
  {
    key: 2,
    title: "NUVOLA ANTIQUE MIRROR",
    images: ["/images/antique-mirror/antique-mirror-nuvola.jpg"],
    description:
      `Nuvola Antique Mirror translates from Italian as "in the clouds."  A dreamy yet consistent pattern with hints of blue and grey highlights.`,
    thickness: "1/4 & 5/32",
    factory_size: "72 X 96",
    stock_size: "36 X 96 or 48 X 72",
    cut_to_size: "Yes",
    edgework: "Seamed, Flat Polished or Beveled",
  },
  {
    key: 3,
    title: "SCURO ANTIQUE MIRROR",
    images: ["/images/antique-mirror/antique-mirror-scuro.jpg"],
    description:
      "​Scuro Antique Mirror embodies its mottled color that is closer to dark than light.  It is a bold antique pattern — like an espresso shot is a condensed form of coffee -  suitable in monochromic spaces that benefit from highlights.",
    thickness: "1/4",
    factory_size: "72 X 96",
    stock_size: "36 X 96 or 48 X 72",
    cut_to_size: "Yes",
    edgework: "Seamed, Flat Polished or Beveled",
  },
  {
    key: 4,
    title: "BIANCO E NERO ANTIQUE MIRROR",
    images: ["/images/antique-mirror/antique-mirror-bianco-e-nero.jpg"],
    description:
      "​Bianco E Nero Antique Mirror means white and black in Italian. Predominantly white with black speckles randomly scattered throughout. It replicates a white mirror that is naturally aged with pitted marks on the backside.",
    thickness: "1/4",
    factory_size: "49 1/4 X 96 1/2",
    stock_size: "49 1/4 X 96 1/2",
    cut_to_size: "Yes",
    edgework: "Seamed, Flat Polished or Beveled",
  },
  {
    key: 5,
    title: "MEDIO ANTIQUE MIRROR",
    images: ["/images/antique-mirror/antique-mirror-medio.jpg"],
    description:
      "​Medio antique mirror is a medium antique pattern – not too strong or not too light – just the right amount of balance between old and new.",
    thickness: "1/4",
    factory_size: "72 X 96",
    stock_size: "36 X 96 or 48 X 72",
    cut_to_size: "Yes",
    edgework: "Seamed, Flat Polished or Beveled",
  },
  {
    key: 6,
    title: "LUCE ANTIQUE MIRROR",
    images: ["/images/antique-mirror/antique-mirror-luce.jpg"],
    description:
      "​Luce Antique Mirror is often used where special attention is needed to be drawn to something. Interestingly, the closer one gets to Luce, the lighter it looks, revealing the light.",
    thickness: "1/4",
    factory_size: "72 X 96",
    stock_size: "36 X 96 or 48 X 72",
    cut_to_size: "Yes",
    edgework: "Seamed, Flat Polished or Beveled",
  },
  {
    key: 7,
    title: "COMETA ANTIQUE MIRROR",
    images: ["/images/antique-mirror/antique-mirror-cometa.jpg"],
    description:
      "​True to their name, comets are a rare celestial spectacle. Similarly, the Cometa Antique Mirror is both traditional and fanciful - often at the same time! The colors of gold accents strewn across it leave behind a trail as you move.",
    thickness: "1/4",
    factory_size: "49 1/4 X 96 1/2",
    stock_size: "49 1/4 X 96 1/2",
    cut_to_size: "Yes",
    edgework: "Seamed, Flat Polished or Beveled",
  },
  {
    key: 8,
    title: "GRIGIO ARGENTO ANTIQUE MIRROR",
    images: ["/images/antique-mirror/antique-mirror-grigio.jpg"],
    description:
      "​Grigio Argento Antique Mirror has a vintage feel with an antique look thanks to the splash of silver on the surface. You are sure to love this fixture for your space! Positioned in the hallway, foyer, or living room, this mirror will get plenty of use and compliments from guests.",
    thickness: "1/4",
    factory_size: "49 1/4 X 96 1/2",
    stock_size: "49 1/4 X 96 1/2",
    cut_to_size: "Yes",
    edgework: "Seamed, Flat Polished or Beveled",
  },
  {
    key: 9,
    title: "LUNA ANTIQUE MIRROR",
    images: ["/images/antique-mirror/antique-mirror-luna.jpg"],
    description:
      "​Display this magical Luna Antique Mirror for guests and you know they will feel enchantingly inspired. The moon-like silver gradations will help any space take on a creative romantic flair. Luna reflects waves of sophistication and style with a touch of mysterious intrigue. What could you see when you gaze upon it?",
    thickness: "1/4",
    factory_size: "49 1/4 X 96 1/2",
    stock_size: "49 1/4 X 96 1/2",
    cut_to_size: "Call",
    edgework: "Flat Polished or Beveled",
  },
  {
    key: 10,
    title: "RAME ANTIQUE MIRROR",
    images: ["/images/antique-mirror/antique-mirror-rame.jpg"],
    description:
      "​Rame Antique Mirror is a hand-crafted, European-style beauty with an antiqued surface layered with dark and light hues that create an alluring reflection reminiscent of European-style 1800’s design.",
    thickness: "1/4 & 3/16",
    factory_size: "72 X 96",
    stock_size: "36 X 96” or 48 X 72",
    cut_to_size: "Yes",
    edgework: "Seamed or Polished",
  },
  {
    key: 11,
    title: "VIOLA ANTIQUE MIRROR",
    images: ["/images/antique-mirror/antique-mirror-viola.jpg"],
    description:
      `Our Viola Antique Mirror distances itself from mundane options by being both powerful and charming at the same time. We love it because it can be used anywhere, especially in places like an office entrance hall or a hotel room where big fixtures are necessary to make a statement. The antique finish is eye-catching without shouting "look at me!"`,
    thickness: "1/4",
    factory_size: "72 X 96",
    stock_size: "36 X 96 or 48 X 72",
    cut_to_size: "Yes",
    edgework: "Seamed or Polished",
  },
]
