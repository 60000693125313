import React from 'react';
import './index.scss';

const TextImageBlock = (props) => {
  return (
    <div className={`text-image-block ${props.image ? 'with-image' : ''} ${props.imageGrow ? 'image-grow' : ''} ${props.imageRight ? 'image-right' : ''}`}>
      {
        props.header &&
          <h2 className="header">{props.header}</h2>
      }
      <div className="wrapper">
        {
          props.image &&
            <div className="image">
              {props.image}
            </div>
        }
        <div className={`content ${props.textPadded ? 'padded' : ''}`}>
          {props.text}
        </div>
      </div>
    </div>
  );
}

export default TextImageBlock;

TextImageBlock.defaultProps = {
  imageGrow: false,
  textPadded: false,
}
